<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card :loading="isLoading">
      <v-card-title class="headline">{{
        "Tải dữ liệu bằng tệp tin excel"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <div class="label-form">Tháng năm</div>
              <MonthPicker
                :hideDetail="false"
                placeholder="chọn tháng"
                v-model="form.month"
                :rules="nameRules"
              />
            </v-col>
            <v-col cols="12">
              <UploadFile
                v-model="form.file"
                :disabled="!form.month"
                @change="handleUpload"
              ></UploadFile>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :disabled="!form.data || !form.data.length"
          :loading="isLoading"
          color="primary"
          @click="importKPI"
        >
          <v-icon left>mdi-cloud-upload</v-icon>
          Tải lên
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import KPI from "@/api/kpi.api";
import XLSX from "xlsx";
import UploadFile from "@/components/upload/UploadFile";
import MonthPicker from "@/components/Date/MonthPicker";
export default {
  components: {
    MonthPicker,
    UploadFile,
  },
  data: () => ({
    isLoading: false,
    show: false,
    form: {
      month: null,
      file: null,
      data: [],
    },
    nameRules: [(v) => !!v || "Không thể bỏ trống"],
  }),
  watch: {
    show(val) {
      if (!val) {
        this.form = this.$options.data.call(this).form;
      }
    },
  },
  methods: {
    showFormImport() {
      this.show = true;
      if (this.$refs["files"]) {
        this.$refs["files"].value = null;
      }
    },
    handleUpload() {
      // if (this.$refs.form.validate()) {
      if (this.form.file && this.form.file.length) {
        let file = this.form.file[0].file;
        const fileReader = new FileReader(); // construction function that can read the file content
        fileReader.onload = (ev) => {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary", // binary
          });

          const wsname = workbook.SheetNames[0]; //take the first sheet

          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //Get the data in this table
          // this.importData.data = ws;
          this.form.data = ws;
        };
        fileReader.readAsBinaryString(file); // read file, trigger onload
      }
    },

    async importKPI() {
      this.isLoading = true;
      await KPI.importkpi({ ...this.form });
      this.isLoading = false;
      this.show = false;
    },
  },
};
</script>
