<template>
  <PageListLayout
    icon="mdi-zip-box-outline"
    title="QUẢN LÝ KPI"
    subTitle="Quản lý KPI"
    titleAdd="Tạo mới khách hàng"
    useAdd
    @add="createItem"
    @reset-filter="resetFilter"
  >
    <template v-slot:side-right>
      <div class="d-flex align-center">
        <div class="d-flex">
          <v-btn class="mr-4" outlined @click="importData()">
            <v-icon class="mr-1">mdi-cloud-upload</v-icon>
            Import File
          </v-btn>
          <v-btn class="mr-4" outlined @click="handle_download_templace()">
            <v-icon class="mr-1">mdi-cloud-download</v-icon>
            Tải file mẫu
          </v-btn>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-1"
              icon
              v-on="on"
              :loading="loadingExport"
              @click="kpiexport()"
            >
              <v-icon> mdi-file-export-outline </v-icon>
            </v-btn>
          </template>
          <span>Kết xuất dữ liệu</span>
        </v-tooltip>
      </div>
    </template>
    <template slot="filter">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="formSearch.search"
          label="Tìm kiếm"
          clearable
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="mt-4" title="Tháng">
        <v-menu
          v-model="showDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formSearch.date"
              append-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              label="Tháng"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formSearch.date"
            @input="showDate = false"
            type="month"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.nhan_vien_id"
          label="Người phụ trách"
          :items="ListEmployee"
          clearable
          single-line
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="style_auto_complate"
                  :title="data.item.name"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.customer_id"
          label="Khách hàng"
          :items="ListCustomer"
          clearable
          single-line
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="style_auto_complate"
                  :title="data.item.name"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
      hideDefaultHeader
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr :title="headers.length">
            <th rowspan="2" class="border-table" width="20px">STT</th>
            <th rowspan="2" class="border-table" width="150px">
              Người phụ trách
            </th>
            <th rowspan="2" class="border-table">Khách hàng</th>
            <th rowspan="2" class="border-table" width="110px">Tháng năm</th>
            <th colspan="3" class="text-center border-table">
              <span style="color: #0d47a1; font-size: 16px">Kế hoạch</span>
            </th>
            <th colspan="3" class="text-center border-table">
              <span style="color: green; font-size: 16px">Thực hiện</span>
            </th>
            <th colspan="3" class="text-center border-table">
              <span style="color: #1a237e; font-size: 16px">Tỷ lệ</span>
            </th>
            <th rowspan="2" class="border-table" width="90px"></th>
          </tr>
          <tr>
            <th
              class="border-table"
              style="border-bottom: 1px solid rgb(170, 170, 170)"
            >
              Phế liệu
            </th>
            <th
              class="border-table"
              style="border-bottom: 1px solid rgb(170, 170, 170)"
            >
              Hàng ngoài
            </th>
            <th
              class="border-table"
              style="border-bottom: 1px solid rgb(170, 170, 170)"
            >
              Xử lý
            </th>
            <th
              class="border-table"
              style="border-bottom: 1px solid rgb(170, 170, 170)"
            >
              Phế liệu
            </th>
            <th
              class="border-table"
              style="border-bottom: 1px solid rgb(170, 170, 170)"
            >
              Hàng ngoài
            </th>
            <th
              class="border-table"
              style="border-bottom: 1px solid rgb(170, 170, 170)"
            >
              Xử lý
            </th>
            <th
              class="border-table"
              style="border-bottom: 1px solid rgb(170, 170, 170)"
            >
              Phế liệu
            </th>
            <th
              class="border-table"
              style="border-bottom: 1px solid rgb(170, 170, 170)"
            >
              Hàng ngoài
            </th>
            <th
              class="border-table"
              style="border-bottom: 1px solid rgb(170, 170, 170)"
            >
              Xử lý
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.ngay_thang`]="{ item }">
        {{ handleDate(item.ngay_thang) }}
      </template>
      <template v-slot:[`item.ke_hoach.phe_lieu`]="{ item }">
        {{ (item.ke_hoach && formatnumber(item.ke_hoach.phe_lieu)) || "--" }}
      </template>
      <template v-slot:[`item.ke_hoach.hang_ngoai`]="{ item }">
        {{ (item.ke_hoach && formatnumber(item.ke_hoach.hang_ngoai)) || "--" }}
      </template>
      <template v-slot:[`item.ke_hoach.xu_ly`]="{ item }">
        {{ (item.ke_hoach && formatnumber(item.ke_hoach.xu_ly)) || "--" }}
      </template>
      <template v-slot:[`item.thuc_hien.phe_lieu`]="{ item }">
        {{ (item.thuc_hien && formatnumber(item.thuc_hien.phe_lieu)) || "--" }}
      </template>
      <template v-slot:[`item.thuc_hien.hang_ngoai`]="{ item }">
        {{
          (item.thuc_hien && formatnumber(item.thuc_hien.hang_ngoai)) || "--"
        }}
      </template>
      <template v-slot:[`item.thuc_hien.xu_ly`]="{ item }">
        {{ (item.thuc_hien && formatnumber(item.thuc_hien.xu_ly)) || "--" }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="loadingUpdate"
                icon
                v-on="on"
                @click="updateKpi(item.id)"
              >
                <v-icon>mdi-update</v-icon>
              </v-btn>
            </template>
            <span>Cập nhật thực hiện Kpi</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="showChiTiet(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Chi tiết</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="deleteItem(item)">
                <v-icon> mdi-trash-can-outline </v-icon>
              </v-btn>
            </template>
            <span>Xoá Kpi</span>
          </v-tooltip>
        </div>
      </template>
    </CustomTable>
    <create-edit
      :ListCustomer="ListCustomer"
      :ListEmployee="ListEmployee"
      ref="form"
      @on-done="getAllList"
    ></create-edit>
    <ImportFile ref="formImport" @on-done="getAllList"></ImportFile>
  </PageListLayout>
</template>
<script>
import CreateEdit from "./create-edit";
import { debounce } from "lodash";
import KPI from "@/api/kpi.api";
import KHACHHANG from "@/api/khachhang";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
import { numberFormat } from "@/utils/moneyFormat";
import ImportFile from "./import-file";
import { saveAs } from "file-saver";
export default {
  components: { CreateEdit, ImportFile },
  data() {
    return {
      showDate: false,
      formSearch: {},
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      data: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      ListEmployee: [],
      loadingUpdate: false,
      headers: [
        // { text: "STT", width: "100", sortable: false },
        // { text: "Icon", value: "icon", sortable: false },
        {
          text: "STT",
          align: "center",
          sortable: false,
          value: "stt",
          width: "20px",
        },
        {
          text: "Người phụ trách",
          align: "start",
          sortable: false,
          value: "nguoi_phu_trach.name",
        },
        {
          text: "Khách hàng",
          align: "start",
          sortable: false,
          value: "khach_hang.name",
        },
        {
          text: "Tháng năm",
          align: "start",
          sortable: false,
          value: "ngay_thang",
        },
        {
          text: "Kế hoạch Phế liệu",
          align: "end",
          sortable: false,
          value: "ke_hoach.phe_lieu",
        },
        {
          text: "Kế hoạch Hàng ngoài",
          align: "end",
          sortable: false,
          value: "ke_hoach.hang_ngoai",
        },
        {
          text: "Kế hoạch Xử lý",
          align: "end",
          sortable: false,
          value: "ke_hoach.xu_ly",
        },
        {
          text: "Thực hiện Phế liệu",
          align: "end",
          sortable: false,
          value: "thuc_hien.phe_lieu",
        },
        {
          text: "Thực hiện Hàng ngoài",
          align: "end",
          sortable: false,
          value: "thuc_hien.hang_ngoai",
        },
        {
          text: "Thực hiện Xử lý",
          align: "end",
          sortable: false,
          value: "thuc_hien.xu_ly",
        },
        {
          text: "Tỷ lệ Phế liệu",
          align: "end",
          sortable: false,
          value: "ty_le.phe_lieu",
        },
        {
          text: "Tỷ lệ Hàng ngoài",
          align: "end",
          sortable: false,
          value: "ty_le.hang_ngoai",
        },
        {
          text: "Tỷ lệ Xử lý",
          align: "end",
          sortable: false,
          value: "ty_le.xu_ly",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
          width: "50",
        },
      ],
      ListCustomer: [],
    };
  },
  computed: {
    tableData() {
      return this.data.map((x, i) => {
        return {
          stt: i + 1,
          ...x,
          ty_le: this.handleTyLe(x.ke_hoach, x.thuc_hien),
        };
      });
    },
  },
  created() {
    // API.getDetail(1).then(res => console.log(res))
    this.getAllEmployee();
    this.getAllCustomers();
    this.getAllList();
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function (val) {
        this.page = 1;
        this.getAllList();
      }, 300),
    },
  },
  methods: {
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    handleDate(date) {
      if (!date) {
        return "";
      }
      let arr = date.split("-");
      return `${arr[1]}/${arr[0]}`;
    },
    async getAllCustomers() {
      try {
        const res = await KHACHHANG.getAllCustomers();
        this.ListCustomer = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.ListEmployee = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    handleTyLe(ke_hoach, thuc_hien) {
      let keys = ["phe_lieu", "hang_ngoai", "xu_ly"];
      let result = {};
      keys.forEach((key) => {
        result[key] =
          this.check_not_empty(ke_hoach, key) &&
          this.check_not_empty(thuc_hien, key)
            ? `${(parseFloat(thuc_hien[key] * 100) / ke_hoach[key]).toFixed(
                2
              )}%`
            : "--";
      });
      return result;
    },
    check_not_empty(data, key) {
      return data && data[key];
    },
    async getAllList() {
      this.loading = true;
      let { data } = await KPI.list({
        page: this.page,
        perPage: this.itemsPerPage,
        ...this.formSearch,
      });
      this.loading = false;
      this.data = data.data;
      this.pageCount = data.last_page;
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa KPI",
        action: () => KPI.destroy(item.id),
        onDone: () => this.getAllList(),
      });
    },
    createItem() {
      this.$refs.form.showFormAdd();
    },
    showChiTiet(e) {
      this.$router.push(`/kpi/quanlykpi/show/${e.id}`);
    },
    async updateKpi(id) {
      try {
        this.loadingUpdate = true;
        const res = await KPI.updateThucHien(id);
        this.$toast.info(`Cập nhật thành công`, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        this.getAllList();
      } finally {
        this.loadingUpdate = false;
      }
    },
    formatnumber(number) {
      let result = numberFormat(number);
      return result;
    },
    importData() {
      this.$refs.formImport.showFormImport();
    },
    async handle_download_templace() {
      const res = await KPI.kpitemplace();
      if (res && res.isDone) {
        saveAs(new Blob([res.data]), "kpiTemplace.xlsx");
      }
    },
    async kpiexport() {
      this.loadingExport = true;
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      let newdate = day + "/" + month + "/" + year;
      const res = await KPI.kpiexport(this.formSearch);
      if (res && res.isDone) {
        saveAs(new Blob([res.data]), "Kpi" + "-" + newdate + ".xlsx");
      }
      this.loadingExport = false;
    },
  },
};
</script>
<style scoped>
.border-table {
  border: 1px solid rgb(170, 170, 170);
}
.style_auto_complate {
  max-width: 200px;
}
</style>
