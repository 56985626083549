<template>
  <v-dialog v-model="show" scrollable width="1200">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT KPI" : "ĐĂNG KÝ KPI"
      }}</v-card-title>
      <v-card-text style="max-height: 600px">
        <v-tabs v-model="tab" left>
          <v-tab> Thông tin đăng ký </v-tab>
          <v-tab :disabled="!form.nhan_vien_id"> Kế hoạch thực hiện </v-tab>
        </v-tabs>
        <br />
        <v-form ref="form">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row>
                <v-col cols="6">
                  <div class="label-form">Tháng</div>
                  <MonthPicker
                    :hideDetail="false"
                    v-model="form.ngay_thang"
                    :rules="nameRules"
                  />
                </v-col>
                <v-col cols="6">
                  <div class="label-form">Người phụ trách</div>
                  <v-autocomplete
                    v-model="form.nhan_vien_id"
                    :items="ListEmployee"
                    item-value="id"
                    item-text="name"
                    placeholder="Người phụ trách"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account"
                    :rules="nameRules"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="label-form">Ghi chú</div>
                  <v-textarea
                    v-model="form.description"
                    placeholder="Ghi chú"
                    outlined
                    dense
                    prepend-inner-icon="mdi-note"
                    no-resize
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="mt-1">
                <v-col cols="12">
                  <div class="label-form">Khách hàng</div>
                  <v-autocomplete
                    v-model="form.customer_id"
                    :items="ListCustomer"
                    :loading="loadingCustomer"
                    item-value="id"
                    item-text="name"
                    placeholder="Khách hàng"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account"
                    :disabled="edit ? true : false"
                    hide-details="auto"
                    multiple
                    @change="onchangeCustomer"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index <= 2">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-else class="grey--text text-caption">
                        (+{{ form.customer_id.length - 3 }})
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="form.ke_hoach.length == 0">
                  <div class="grey--text text-center pa-2">
                    Vui lòng chọn khách hàng phụ trách
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  v-for="(item, index) in form.ke_hoach"
                  :key="`cus-${item.id}`"
                >
                  <div class="d-flex align-center mb-2">
                    <v-icon class="mr-2">mdi-folder-account</v-icon>
                    <div style="font-size: 16px">
                      {{ ListCustomer.find((x) => x.id == item.id).name }}
                    </div>
                  </div>
                  <v-row>
                    <v-col cols="4">
                      <div class="label-form">Phế liệu</div>
                      <v-text-field
                        v-model="form.ke_hoach[index].phe_lieu"
                        placeholder="Phế liệu"
                        outlined
                        dense
                        prepend-inner-icon="mdi-cash-usd"
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <div class="label-form">Hàng ngoài</div>
                      <v-text-field
                        v-model="form.ke_hoach[index].hang_ngoai"
                        placeholder="Hàng ngoài"
                        outlined
                        dense
                        prepend-inner-icon="mdi-cash-usd"
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <div class="label-form">Xử lý</div>
                      <v-text-field
                        v-model="form.ke_hoach[index].xu_ly"
                        placeholder="Xử lý"
                        outlined
                        dense
                        prepend-inner-icon="mdi-cash-usd"
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Hủy thao tác</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
          :disabled="form.ke_hoach.length == 0"
        >
          <v-icon left>mdi-plus</v-icon>
          Đăng ký KPI
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import KPI from "@/api/kpi.api";
import MonthPicker from "@/components/Date/MonthPicker";
import KHACHHANG from "@/api/khachhang";
export default {
  components: {
    MonthPicker,
  },
  props: {
    ListEmployee: { type: Array, default: () => [] },
  },
  data: () => ({
    tab: 0,
    show: false,
    edit: false,
    loadingCustomer: false,
    btnLoading: false,
    form: {
      customer_id: [],
      ke_hoach: [],
    },
    nameRules: [(v) => !!v || "Không thể bỏ trống"],
    codeRules: [(v) => !!v || "Không thể bỏ trống"],
    ListCustomer: [],
  }),
  computed: {},
  watch: {
    show(val) {
      if (!val) {
        this.tab = 0;
      }
    },
    "form.nhan_vien_id": {
      deep: true,
      handler(val) {
        if (val) {
          this.tab = 1;
          this.getAllCustomers({ person_in_charge_id: val });
        }
      },
    },
    "form.customer_id": {
      handler(val) {},
    },
  },
  methods: {
    onchangeCustomer(val) {
      let result = [];
      val.forEach((id) => {
        let find = this.form.ke_hoach.find((x) => x.id == id);
        if (!find) {
          result.push({
            id: id,
            phe_lieu: null,
            hang_ngoai: null,
            xu_ly: null,
          });
        } else {
          result.push(find);
        }
      });
      this.$set(this.form, "ke_hoach", [...result]);
    },
    async getAllCustomers(params) {
      this.loadingCustomer = true;
      const res = await KHACHHANG.getAllCustomers(params);
      this.ListCustomer = res.data;
      this.loadingCustomer = false;
    },
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {
        ke_hoach: [],
        customer_id: [],
      };
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          const res = await KPI.create(data);
          if (res && res.isDone) {
            this.$emit("on-done");
          }
        } finally {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
